import { Component, OnInit, ViewChild, Output, EventEmitter, Injector } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ModalDirective } from "ngx-bootstrap";
import * as _ from 'lodash';
import {
    EmployeeReportsServiceServiceProxy,
    EmployeeBalanceOutputDto,
    EmployeeMonthlyPayOutputDto,
    VacationLookupLookupTableDto
} from "@shared/service-proxies/service-proxies";
import { EmployeeDto, VacationsServiceProxy, GetEmployeeForViewDto, NameValueOfString, EmployeesServiceProxy } from '../../../shared/service-proxies/service-proxies';
import { AppConsts } from "@shared/AppConsts";
import * as moment from 'moment';
import { EmployePayslipComponent } from "../dashboard/employe-payslip/employe-payslip.component";

@Component({
    selector: "employee-reports",
    templateUrl: "./employee-reports.component.html",
    styleUrls: ["./employee-reports.component.css"]
})
export class EmployeeReportsComponent extends AppComponentBase implements OnInit {
    @ViewChild("employeeReportModal") modal: ModalDirective;
    @ViewChild('paySlip') paySlip: EmployePayslipComponent;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    employee: NameValueOfString;
    active = false;
    saving = false;
    showPaySlip = false;
    month: any;
    dates: any[] = [];
    employeeCode: string = '';


    constructor(injector: Injector, private _employeeReportsServiceServiceProxy: EmployeeReportsServiceServiceProxy,
        private _vacationsServiceProxy: VacationsServiceProxy) {
        super(injector);
    }

    ngOnInit() {
    }
    leaveCodes: VacationLookupLookupTableDto[] = [];
    leaveCodeId = null;
    employeeBalance: EmployeeBalanceOutputDto = new EmployeeBalanceOutputDto();
    showEmployeeBalance = false;

    loadEmployeeBalance(employee: NameValueOfString) {
        this.employeeBalance.balance = 0;
        this.employee = employee;
        this.getLeaveCodes();
        this.showEmployeeBalance = true;
        this.showEmployeeMonthlyPay = false;
        this.showPaySlip = false;
        this.modal.show();
    }

    employeeMonthlyPay: EmployeeMonthlyPayOutputDto[] = [];
    showEmployeeMonthlyPay = false;

    loadEmployeeMonthlyPay(employee: NameValueOfString) {
        this.employee = employee;

        this._employeeReportsServiceServiceProxy.getEmployeeMonthlyPay(+this.employee.value, moment().startOf('day')).subscribe(result => {
            this.employeeMonthlyPay = [];
            this.employeeMonthlyPay = result;
        });
        this.showPaySlip = false;
        this.showEmployeeBalance = false;
        this.showEmployeeMonthlyPay = true;

        this.modal.show();
    }


    loadEmployeePaySlip(employee: NameValueOfString) {
        //ghani
        this.showEmployeeBalance = false;
        this.employee = employee;
        this.showEmployeeMonthlyPay = false;
        this.showPaySlip = true;
        this.month = null;
        this.getLast12Month();

        this.modal.show();

    }

    getLast12Month() {
        var months = [{ val: 1, text: this.l("January") }, { val: 2, text: this.l("February") }, { val: 3, text: this.l("March") }, { val: 4, text: this.l("April") },
        { val: 5, text: this.l("May") }, { val: 6, text: this.l("June") }, { val: 7, text: this.l("July") }, { val: 8, text: this.l("August") },
        { val: 9, text: this.l("September") }, { val: 10, text: this.l("October") }, { val: 11, text: this.l("November") }, { val: 12, text: this.l("December") }];

        var currentDate = new Date();
        this.dates = [];
        for (let i = 1; i <= 12; i++) {
            currentDate.setMonth(currentDate.getMonth() - 1)
            this.dates.push({
                value: months[currentDate.getMonth()].val + '-' + currentDate.getFullYear(),
                text: months[currentDate.getMonth()].text + ' - ' + currentDate.getFullYear(),
                monthId: months[currentDate.getMonth()].val,
                year: currentDate.getFullYear()
            });
        }

    }

    downloadPaySlip(date) {
        //test
        let selectedMonth = this.dates.find(d => d.value == date);
        let paramDate = moment();
        paramDate.set('month', selectedMonth.monthId - 1);
        paramDate.set('year', selectedMonth.year);

        this._employeesServiceProxy.getEmployeePaySlipAsync(Number(this.employee.value), paramDate)
            .subscribe(result => {
                if (result != null && result.length > 0) {
                    const headerData = {
                        netAmount: result[0].netAmount,
                        amountInWords: result[0].amountInWords,
                        name: result[0].name,
                        iban: result[0].iban,
                        reporttitle: result[0].reporttitle,
                        date: date,
                        employeeId: result[0].personnelNumber
                    };
                    const groupedData = _.groupBy(result, y => y.payfItemType)
                    this.paySlip.paySlipData = groupedData;
                    this.paySlip.headerData = headerData;
                    this.paySlip.show();
                } else {
                    this.showPaySlipError();
                }
            }, error => {
                this.showPaySlipError();
            });
        // this._employeesServiceProxy.checkEmployeeHasPaySlipforMonth(this.employee.value, date).subscribe(result => {
        //     if (result) {
        //         const url = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadPaySlipForEmployee?emplyeeId=" + this.employee.value + "&date=" + date;
        //         window.open(url, '_blank');
        //     }
        // });
    }
    showPaySlipError() {
        if (this.localization.currentLanguage.name == "ar")
            this.message.warn('عفوا لا يوجد سير مرتب لهذا الشهر')
        else
            this.message.warn('Sorry No payslip for this month')
        return;
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    getLeaveCodes() {
        if (this.leaveCodes.length == 0) {
            this._vacationsServiceProxy.getAllLeaveCodeForLookupTable().subscribe(result => {
                this.leaveCodes = result;
            });
        }
    }

    onChangeLeaveCode() {
        this._employeesServiceProxy.getEmployeeForView(Number(this.employee.value)).subscribe(result => {
            this.employeeCode = result.employee.code

            if (this.leaveCodeId) {
                var leaveCode = this.leaveCodes.find(obj => obj.id == this.leaveCodeId);
                if (leaveCode) {
                    this._employeeReportsServiceServiceProxy.getEmployeeBalance(undefined,
                        this.employeeCode, leaveCode.code, moment(new Date()), moment(new Date())).subscribe(result => {
                            this.employeeBalance = result;
                            // this.leaveCodeId = 2;
                        });
                }
            }

        });



    }

}
